// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // firebase: {
  //   apiKey: '',
  //   authDomain: '',
  //   databaseURL: '',
  //   projectId: '',
  //   storageBucket: '',
  //   messagingSenderId: '',
  //   appId: '',
  //   measurementId: ''
  // },
  firebase: {
    apiKey: "AIzaSyDzEasx18K2IHc0UIN0yNTm2RlPMOzMUKE",
    authDomain: "txomin-app.firebaseapp.com",
    projectId: "txomin-app",
    storageBucket: "txomin-app.appspot.com",
    messagingSenderId: "1057315393299",
    appId: "1:1057315393299:web:7d092462c82ec04ad3d4e6"
  },
  onesignal: {
    appId: '',
    googleProjectNumber: '',
    restKey: ''
  },
  stripe: {
    sk: ''
  },
  general: {
    symbol: '€',
    code: 'EUR'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
