import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layouts/admin/admin.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { AuthGuard } from './guard/auth.guard';
import { SetupAuthGuard } from './setupGuard/auth.guard';
import { CommerceModule } from './commerce/commerce.module';
import { ComerciodetailsModule } from './comercio-details/comerciodetails.module';
import { AuthYincanaGuard } from './guard/auth-yincana.guard';
import { YincanaAdminModule } from './yincana-admin/yincana-admin.module';
const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'transferencias',
        pathMatch: 'full'
      },

      {
        path: 'transferencias',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'clientes',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'comercios',
        loadChildren: () => import('./commerce/commerce.module').then(m => m.CommerceModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'cliente',
        loadChildren: () => import('./orderdetails/orderdetails.module').then(m => m.OrderdetailsModule),
        // canActivate: [AuthGuard]
      },
      {
        path: 'comercio',
        loadChildren: () => import('./comercio-details/comerciodetails.module').then(m => m.ComerciodetailsModule),
        // canActivate: [AuthGuard]
      },
      {
        path: 'yincana-admin',
        loadChildren: () => import('./yincana-admin/yincana-admin.module').then(m => m.YincanaAdminModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'yincana',
        loadChildren: () => import('./yincana/yincana.module').then(m => m.YincanaModule),
        // canActivate: [AuthYincanaGuard]
      },
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        // canActivate: [SetupAuthGuard]
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
